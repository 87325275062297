/*--
Author: W3layouts
Author URL: http://w3layouts.com
License: Creative Commons Attribution 3.0 Unported
License URL: http://creativecommons.org/licenses/by/3.0/
--*/
/* Prestige Mills Global CSS ------------------------ */

.btn {
    line-height: 18px;
    font-family: sofia-pro, sans-serif;
    font-style: normal;
    font-weight: 600;
    padding: 19px 42px;
    font-size: 14px;
    border-radius: 0;
    letter-spacing: 0.50px;
    -webkit-transition: background-color 0.4s ease-out;
    -moz-transition: background-color 0.4s ease-out;
    -o-transition: background-color 0.4s ease-out;
    transition: background-color 0.4s ease-out;
}

.btn:focus {
    outline: none;
    box-shadow: none;
}

.btn-white {
    color: #000000;
    background-color: #ffffff;
    border: 2px solid #000000;
}

.btn-black {
    color: #ffffff;
    background-color: #000000;
    border: 2px solid #000000;
}

.btn-white:hover {
    color: #ffffff;
    background-color: #000000;
}

.btn-black:hover {
    color: #000000;
    background-color: #ffffff;
}

.overlay-btn {
    line-height: 18px;
    font-family: sofia-pro, sans-serif;
    font-style: normal;
    font-weight: 600;
    padding: 19px 42px;
    font-size: 14px;
    border-radius: 0;
    letter-spacing: 0.50px;
    -webkit-transition: background-color 0.4s ease-out;
    -moz-transition: background-color 0.4s ease-out;
    -o-transition: background-color 0.4s ease-out;
    transition: background-color 0.4s ease-out;
}

.overlay-btn-white {
    color: #ffffff;
    background-color: #000000;
    border: 2px solid #ffffff;
}

.overlay-btn-white:hover {
    text-decoration: none;
    background-color: #ffffff;
    color: #000000;
}

.overlay-btn-black {
    color: #000000;
    background-color: #ffffff;
    border: 2px solid #ffffff;
}

.overlay-btn-black:hover {
    text-decoration: none;
    background-color: #000000;
    color: #ffffff;
}

button {
    cursor: pointer;
}

.modals-wrapper {
    display: none;
}

.wrapper-loader {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 99998;
}

.common-loader {
    display: none;
    margin-top: -35px;
    margin-left: -35px;
    font-size: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    text-indent: -9999em;
    border-top: 10px solid rgba(200, 200, 200, 0.8);
    border-right: 10px solid rgba(200, 200, 200, 0.8);
    border-bottom: 10px solid rgba(200, 200, 200, 0.8);
    border-left: 10px solid #000000;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: customloader 1.1s infinite linear;
    animation: customloader 1.1s infinite linear;
    z-index: 99999;
}

.image-loader {
    display: block;
    margin-top: -35px;
    margin-left: -35px;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    text-indent: -9999em;
    border-top: 10px solid rgba(200, 200, 200, 0.8);
    border-right: 10px solid rgba(200, 200, 200, 0.8);
    border-bottom: 10px solid rgba(200, 200, 200, 0.8);
    border-left: 10px solid #000000;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: customloader 1.1s infinite linear;
    animation: customloader 1.1s infinite linear;
    z-index: 99999;
}

.image-loader,
.image-loader:after,
.common-loader,
.common-loader:after {
    border-radius: 50%;
    width: 70px;
    height: 70px;
}

@-webkit-keyframes customloader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes customloader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 575px) {
    .btn {
        display: inline-block;
        width: 100%;
        min-width: 220px;
        max-width: 335px;
        padding: 19px 20px;
    }

    .overlay-btn-white {
        margin: 0 10px;
    }

    .overlay-btn-black {
        margin: 0 10px;
    }
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

.overlay-active {
    overflow: hidden;
}

.page-wrapper .sections.nav-sections {
    display: none;
}


/* Header CSS ------------------------ */

.page-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    right: 0;
    margin: 0;
    padding: 44px 0 20px 0;
    z-index: 999;
    border: 0;
}

.page-header.sticky {
    background-color: #ffffff;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
}

.page-header .header-container {
    padding: 0 15px;
    max-width: 1663px;
    margin: 0 auto;
}

.page-header .header-menu {
    font-size: 0;
}

.page-header .menu-col {
    display: inline-block;
    width: calc(calc(100% - 241px) / 2);
}

.page-header .second-menu-col {
    width: 241px;
}

.page-header .search-call {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url('../images/search.svg');
    background-size: 20px 20px;
    background-repeat: no-repeat;
}

.page-layout-custom_collection .page-header .search-call {
    position: fixed;
}

.page-header .right-menu {
    float: right;
}

.page-header .menu-item {
    font-family: sofia-pro, sans-serif;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    font-size: 14px;
    float: left;
    color: #000000;
    text-transform: uppercase;
    padding: 0 20px;
    letter-spacing: 0.50px;
    min-width: 40px;
    min-height: 10px;
}

.page-header .menu-item:first-of-type {
    padding: 0 20px 0 0;
}

.page-header .menu-item:last-of-type {
    padding: 0 0 0 20px;
}

.page-header .menu-item a {
    color: inherit;
    text-decoration: none;
}

.page-header .menu-item .menuitem-icon {
    display: inline-block;
    vertical-align: top;
    width: 24px;
    height: 20px;
    margin: 0 0 0 10px;
}

.page-header .site-logo {
    display: inline-block;
    width: 241px;
    height: 60px;
}

.page-header .white-logo {
    display: none;
}

.page-header .white-logo-mobile {
    display: none;
}

.page-header .black-logo-mobile {
    display: none;
}

.page-header .menu-call .white-menu-hamburger,
.page-header .menu-call .black-menu-hamburger {
    display: none;
}

.page-header .menu-call .white-menu-close,
.page-header .menu-call .black-menu-close {
    display: none;
}

.page-header .header-search .white-menu-search,
.page-header .header-search .black-menu-search {
    display: none;
}

.page-header .header-search .white-menu-search-close,
.page-header .header-search .black-menu-search-close {
    display: none;
}

@media screen and (max-width: 1450px) {
    .page-header .menu-col {
        width: calc(calc(100% - 175px) / 2);
    }

    .page-header .second-menu-col {
        width: 175px;
    }

    .page-header .site-logo {
        width: 175px;
        height: 45px;
    }

    .page-header .menu-item {
        padding: 0 15px;
    }
}

@media screen and (max-width: 1200px) {
    .page-header .menu-col {
        width: calc(calc(100% - 150px) / 2);
    }

    .page-header .second-menu-col {
        width: 150px;
    }

    .page-header .site-logo {
        width: 150px;
        height: 34px;
    }

    .page-header .menu-item {
        font-size: 12px;
        padding: 0 5px;
    }
}

@media screen and (max-width: 1024px) {
    .page-header {
        position: fixed;
        padding: 15px 0;
        background-color: #ffffff;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    }

    .overlay-active .page-header {
        background-color: #000000;
    }

    .page-header .header-container {
        padding: 0 20px;
    }

    .page-header .left-menu {
        display: none;
    }

    .page-header .right-menu {
        display: none;
    }

    .page-header .menu-col {
        vertical-align: middle;
    }

    .page-header .black-logo {
        display: none;
    }

    .page-header .black-logo-mobile {
        display: block;
    }

    .page-header .menu-call .black-menu-hamburger,
    .page-header .menu-call .white-menu-hamburger {
        width: 20px;
        height: 14px;
    }

    .page-header .menu-call .black-menu-hamburger {
        display: inline-block;
    }

    .page-header .menu-call .black-menu-close,
    .page-header .menu-call .white-menu-close {
        width: 10px;
        height: 10px;
    }

    .page-header .menu-call.active .menu-close {
        display: inline-block;
    }

    .page-header .menu-call.active .black-menu-hamburger {
        display: none;
    }

    .page-header .header-search .black-menu-search,
    .page-header .header-search .white-menu-search {
        float: right;
        width: 20px;
        height: 20px;
    }

    .page-header .header-search .black-menu-search {
        display: block;
    }

    .page-header .header-search.active .white-menu-search-close,
    .page-header .header-search.active .black-menu-search-close {
        float: right;
        width: 10px;
        height: 10px;
    }

    .page-header .header-search.active .black-menu-search-close {
        display: block;
    }

    .page-header .header-search.active .menu-search {
        display: none;
    }

    .overlay-active .page-header .black-logo-mobile {
        display: none;
    }

    .overlay-active .page-header .white-logo-mobile {
        display: block;
    }

    .overlay-active .page-header .menu-call .white-menu-hamburger {
        display: block;
    }

    .overlay-active .page-header .menu-call.active .white-menu-hamburger {
        display: none;
    }

    .overlay-active .page-header .menu-call.active .white-menu-close {
        display: block;
    }

    .overlay-active .page-header .header-search .white-menu-search {
        display: block;
    }

    .overlay-active .page-header .header-search.active .white-menu-search {
        display: none;
    }

    .overlay-active .page-header .header-search.active .black-menu-search {
        display: none;
    }

    .overlay-active .page-header .header-search.active .white-close-icon {
        display: block;
    }

    .overlay-active .page-header .menu-call .black-menu-hamburger {
        display: none;
    }

    .overlay-active .page-header .header-search .black-menu-search {
        display: none;
    }

    .overlay-active .page-header .header-search.active .black-menu-search-close {
        display: none;
    }

    .overlay-active .page-header .header-search.active .white-menu-search-close {
        display: block;
    }
}


/* Filters section CSS --------------- */

.filters-wrapper {
    position: absolute;
    bottom: 0;
    padding: 20px 0 65px;
    background-color: #ffffff;
    left: 50%;
    opacity: 1;
    transform: translate(-50%, 0);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    z-index: 99;
    -webkit-transition: opacity 0.2s ease-out;
    -moz-transition: opacity 0.2s ease-out;
    -o-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
}

.overlay-filters .filters-wrapper {
    position: static;
    transform: translate(0, 0);
}

.filters-wrapper.search-active {
    opacity: 0 !important;
    z-index: -99;
}

.filters-wrapper .filters-section {
    width: 1250px;
    height: 100%;
    visibility: hidden;
    opacity: 0;
}

.filters-wrapper .owl-carousel .owl-stage {
    width: 100% !important;
}

.filters-wrapper .filters-inner {
    font-size: 0;
    height: 100%;
    padding: 35px 27px 0;
}

.filters-wrapper .swipe-bar {
    display: none;
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 104px;
    padding: 0 0 0 25px;
    background-color: #ffffff;
    z-index: 99;
}

.filters-wrapper .swipe-option {
    display: block;
    color: #2C2C2C;
    font-size: 12px;
    line-height: 15px;
    margin: 17px 0 0 0;
}

.filters-wrapper .swipe-icon {
    width: 16px;
    height: 8px;
}

.filters-wrapper .filters-cols {
    display: inline-block;
    width: 188px;
    padding: 0 29px;
    vertical-align: top;
}

.filters-wrapper .filters-cols:last-of-type {
    width: 285px;
}

.filters-wrapper .filters-cols h3 {
    font-size: 16px;
    letter-spacing: 0.50px;
    line-height: 40px;
    font-family: sofia-pro, sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #000000;
    margin: 0;
}

.filters-wrapper .filters-cols li {
    position: relative;
    font-size: 14px;
    color: #2C2C2C;
    line-height: 30px;
    font-family: sofia-pro, sans-serif;
    font-style: normal;
    font-weight: 300;
    margin: 0;
}

.filters-wrapper .filters-cols li img {
    margin: 0 10px 0 0;
    display: inline-block;
    width: auto;
}

.filters-wrapper .filters-cols a {
    position: relative;
    color: inherit;
    text-decoration: none;
    word-break: break-all;
    text-transform: capitalize;
    outline: 0;
}

.filters-wrapper .filters-cols .filter-option.selected {
    font-weight: bold;
}

.filters-wrapper .filters-cols .filter-option.selected::after {
    position: absolute;
    left: -12.5px;
    top: 10px;
    width: 8px;
    height: 8px;
    content: " ";
    margin-top: -3px;
    background-image: url('../images/black-close-icon.svg');
    background-size: 8px 8px;
    background-repeat: no-repeat;
}

.filters-wrapper .filters-submit {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 165px;
    border: 0;
    outline: 0;
    padding: 0;
    z-index: 101;
    color: #000000;
    text-align: right;
    background-color: transparent;
}

.filters-wrapper .filters-submit-icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 80px;
    height: 80px;
    opacity: 1;
    margin: 0 0 0 15px;
    background-color: #000000;
}

.filters-wrapper .filters-submit:disabled .filters-submit-icon {
    opacity: 0;
}

.filters-wrapper .filters-submit .arrow-icon {
    position: absolute;
    width: 16px;
    height: 8px;
    margin: -4px 0 0 -8px;
    top: 50%;
    left: 50%;
}

.filters-wrapper .filters-search {
    display:none;
    position: absolute;
    left: 0;
    top: 50%;
    width: 80px;
    height: 80px;
    background-color: #F0F1F1;
    border: 0;
    outline: 0;
    transform: translate(0, -50%);
    z-index: 1000;
    box-shadow: none;
}

.filters-wrapper .filters-search .search-icon {
    width: 21px;
    height: 21px;
}

.filters-wrapper .filters-search:focus {
    outline: 0;
}

.search-bar .search-close {
    position: absolute;
    left: 0;
    width: 80px;
    height: 80px;
    border: 0;
    outline: 0;
    box-shadow: none;
    background-color: #F0F1F1;
    background-image: url('../images/black-close-icon.svg');
    background-size: 10px 10px;
    background-position: center;
    background-repeat: no-repeat;
}

.search-bar .search-close:hover {
    background-color: #000000;
    background-image: url('../images/white-close-icon.svg');
}

.search-bar .close-icon {
    width: 10px;
    height: 10px;
}

.search-bar .search-submit {
    position: absolute;
    right: 0;
    width: 80px;
    height: 80px;
    background-color: #000000;
    border: 0;
    outline: 0;
    box-shadow: none;
}

.search-bar .search-icon {
    width: 21px;
    height: 21px;
}

.search-bar {
    position: absolute;
    bottom: 260px;
    left: 50%;
    right: 0;
    opacity: 0;
    width: 1304px;
    background-color: #ffffff;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    z-index: -999;
    transform: translate(-50%, 0);
    -webkit-transition: opacity 0.2s ease-out;
    -moz-transition: opacity 0.2s ease-out;
    -o-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
}

.search-bar .search-field {
    width: 100%;
    height: 80px;
    font-size: 24px;
    line-height: 50px;
    letter-spacing: 0.50px;
    border: 0;
    outline: 0;
    padding: 30px 15px 26px 95px;
    box-shadow: none;
    font-family: sofia-pro, sans-serif;
    font-style: normal;
    font-weight: 600;
}

.search-bar .search-field::placeholder {
    color: #94989A;
}

.search-bar .search-field::-webkit-input-placeholder {
    color: #94989A;
    opacity: 0.5;
}

.search-bar .search-field:-moz-placeholder {
    color: #94989A;
}

.search-bar .search-field::-moz-placeholder {
    color: #94989A;
}

.search-bar .search-field:-ms-input-placeholder {
    color: #94989A;
}

.search-bar.search-active {
    opacity: 1;
    z-index: 99;
}

@media screen and (min-width: 1025px) {
    .filters-wrapper .swipe-bar {
        display: none !important;
    }
}

@media screen and (max-width: 1500px) {
    .overlay-filters .filters-wrapper {
        margin: 0 0 0 80px;
    }
}

@media screen and (max-width: 1350px) {
    .filters-wrapper {
        left: 20px;
        right: 0;
        padding: 20px 0 40px;
        transform: translate(0, 0);
    }

    .filters-wrapper .filters-section {
        width: 100%;
        height: 100%;
        max-width: 1250px;
    }

    .filters-wrapper .filters-submit {
        bottom: -40px;
        background-color: #ffffff;
    }

    .filters-wrapper .filters-search {
        display: none;
    }

    .search-bar {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .filters-wrapper {
        padding: 10px 0 40px;
    }

    .overlay-filters .filters-wrapper {
        padding: 0;
        height: 550px;
    }

    .filters-wrapper .filters-inner {
        padding: 15px 10px;
    }

    .filters-wrapper .filters-cols {
        padding: 0 14px;
    }

    .overlay-filters .filters-wrapper .filters-cols .filter-option.selected::after {
        background-image: url('../images/white-close-icon.svg');
    }

    .filters-wrapper .swipe-bar {
        display: block;
    }

    .overlay-filters .filters-wrapper {
        margin: 0 !important;
        background-color: #000000;
    }

    .overlay-filters .filters-wrapper .filters-cols h3 {
        color: #ffffff;
    }

    .overlay-filters .filters-wrapper .filters-cols li {
        color: #95989A;
    }

    .overlay-filters .filters-wrapper .filters-cols .filter-option.selected {
        color: #ffffff;
    }

    .overlay-filters .filters-wrapper .filters-submit {
        display: none !important;
    }
}


/* Footer CSS ------------------------ */

.page-footer ul,
.page-footer ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

.page-footer {
    overflow: auto;
}
.cms-page-view , .page-layout-custom_pdp , .page-layout-pm_custom_home , .dealers-index-index , .page-layout-custom_collection{
    .page-footer .footer-container {
        width: 100%;
    }
}
.page-footer .footer-container {
    width: 1560px;
    float: right;
    font-size: 0;
}
.page-layout-pm_custom_home , .contactus-index-index {
    .page-footer .footer-container{
        width: auto;
        float: none;
    }

}
.cms-page-view , .page-layout-custom_pdp , .page-layout-pm_custom_home, .dealers-index-index , .page-layout-custom_collection {
    .page-footer .footer-container .footer-left{
        width:100%;
    }
}
    .page-footer .footer-container .footer-left {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        height: 220px;
        padding: 0 40px;
    }

    .page-footer .footer-container .search-call .search-icon {
        width: 20px;
        height: 20px;
    }

    .page-footer .footer-container .footer-left {
        background-color: #f0f1f1;
        text-align: center;
    }

    .page-footer .footer-container .footer-menu {
        display: inline-block;
        line-height: 220px;
    }

    .page-footer .footer-container .menu-item {
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        margin: 0;
        font-size: 14px;
        float: left;
        color: #000000;
        text-transform: uppercase;
        padding: 0 20px;
        letter-spacing: 0.50px;
    }

    .page-footer .footer-container .menu-item a {
        color: inherit;
        text-decoration: none;
    }

    .page-footer .footer-container .menu-item .menuitem-icon {
        width: 24px;
        height: 20px;
        margin: 0 0 4px 10px;
    }

    .page-footer .footer-container .footer-right {
        display: inline-block;
        vertical-align: top;
        width: 429px;
        height: 220px;
        text-align: center;
        line-height: 220px;
        background-color: #000000;
    }

    .page-footer .footer-container .footer-right-inner {
        overflow: hidden;
    }

    .page-footer .footer-container .footer-logo {
        width: 210px;
        margin: 0 45px 0 0;
    }

    .page-footer .footer-container .footer-right-arrow {
        width: 16px;
        height: 8px;
    }

    .page-footer .overlay-container {
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: #000000;
        z-index: 998;
    }

    .page-footer .overlay-container .overlay-inner {
        display: table;
        width: 100%;
        height: 100%;
        max-width: 1250px;
    }

    .page-footer .overlay-container .overlay-menu {
        display: table-cell;
        vertical-align: middle;
    }

    .page-footer .overlay-container .menu-item {
        position: relative;
        font-size: 14px;
        letter-spacing: 0.50px;
        line-height: 18px;
        color: #ffffff;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0;
        padding: 17.5px 0 17.5px 100px;
        border-top: 1px solid #070707;
    }

    .page-footer .overlay-container .menu-item:nth-child(2) {
        border: 0;
    }

    .page-footer .overlay-container .menu-item:before {
        position: absolute;
        left: 0;
        top: -1px;
        content: " ";
        width: 100px;
        height: 1px;
        background-color: #252525;
    }

    .page-footer .overlay-container .menu-item:nth-child(2):before {
        display: none;
    }

    .page-footer .overlay-container .menu-item:nth-child(7) {
        padding: 77.5px 0 17.5px 100px;
    }

    .page-footer .overlay-container .menu-item a {
        color: inherit;
    }

    .page-footer .overlay-container .menu-item.has-searchicon {
        display: none;
    }

    .page-footer .overlay-container .menu-item .menuitem-icon {
        display: inline-block;
        vertical-align: top;
        width: 24px;
        height: 20px;
        margin: 0 0 0 10px;
    }

    .page-footer .overlay-search {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 998;
    }

    .page-footer .overlay-search .overlay-inner {
        position: absolute;
        max-width: 1200px;
        width: 100%;
        left: 50%;
        top: 50%;
        height: 80px;
        transform: translate(-50%, -50%);
    }

    .page-footer .overlay-search .search-field {
        height: 80px;
        width: 100%;
        font-size: 24px;
        line-height: 50px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.50px;
        border: 0;
        outline: 0;
        padding: 20px 15px 20px 95px;
        box-shadow: none;
    }

    .page-footer .overlay-search .search-close {
        position: absolute;
        left: 0;
        width: 80px;
        height: 80px;
        border: 0;
        outline: 0;
        box-shadow: none;
        background-color: #F0F1F1;
        background-image: url('../images/black-close-icon.svg');
        background-size: 10px 10px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .page-footer .overlay-search .search-close:hover {
        background-color: #000000;
        background-image: url('../images/white-close-icon.svg');
    }

    .page-footer .overlay-search .close-icon {
        width: 10px;
        height: 10px;
    }

    .page-footer .overlay-search .search-submit {
        position: absolute;
        right: 0;
        width: 80px;
        height: 80px;
        background-color: #000000;
        border: 0;
        outline: 0;
        box-shadow: none;
    }

    .page-footer .overlay-search .search-icon {
        width: 21px;
        height: 21px;
    }

    .page-footer .overlay-filters {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 1000;
    }

    .page-footer .overlay-filters .overlay-inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .page-footer .overlay-filters .overlay-inner .close-filterpopup {
        position: absolute;
        width: 80px;
        height: 80px;
        left: -80px;
        outline: 0;
        border: 0;
        background-color: #F0F1F1;
        background-image: url('../images/black-close-icon.svg');
        background-size: 10px 10px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .page-footer .overlay-filters .overlay-inner .close-filterpopup:hover {
        background-color: #000000;
        background-image: url('../images/white-close-icon.svg');
    }

    .page-footer .overlay-filters .overlay-buttons {
        display: none;
        position: absolute;
        bottom: 50px;
        width: 100%;
    }

    .page-footer .overlay-filters .overlay-title {
        display: none;
        padding: 40px 20px 20px 20px;
    }

    .page-footer .overlay-filters .refine-title {
        font-size: 35px;
        color: #ffffff;
        letter-spacing: 0.10px;
        display: inline-block;
        line-height: 35px;
        max-width: 150px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        text-align: left;
    }

    .page-footer .overlay-filters .refine-option {
        display: inline-block;
        max-width: 115px;
        padding: 0 0 0 40px;
    }

    .page-footer .overlay-filters .refine-icon {
        width: 15px;
        height: 8px;
    }

    .page-footer .overlay-filters .swipe-option {
        display: block;
        font-size: 12px;
        line-height: 15px;
        color: #94989A;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
    }

    .page-footer .collection-filter-call {
        display: none;
        position: fixed;
        bottom: 50px;
        left: 50%;
        width: 60px;
        height: 60px;
        background-color: #ffffff;
        background-image: url('../images/filter.svg');
        background-size: 21px 21px;
        background-position: center;
        background-repeat: no-repeat;
        transform: translate(-50%, 0);
        z-index: 100;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    }

    .page-footer .filter-counter {
        display: none;
        position: absolute;
        top: -10px;
        right: -10px;
        display: block;
        width: 20px;
        height: 20px;
        color: #ffffff;
        font-size: 12px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        background: #000000;
        text-align: center;
    }

    .page-layout-custom_collection .page-footer {
        background-color: #f0f1f1;
    }

    .page-layout-custom_collection .page-footer .footer-container .footer-left {
        background-color: #f0f1f1;
    }

    .contactus-index-index .page-footer {
        background-color: #f0f1f1;
    }

    .contactus-index-index .page-footer .footer-container .footer-left {
        display: block;
    }

    .cms-no-route .page-footer {
        background-color: #f0f1f1;
    }

    .cms-no-route .page-footer .footer-container .footer-left {
        background-color: #ffffff;
    }

    @media screen and (max-width: 1575px) {
        .page-layout-pm_custom_home , .contactus-index-index {
            .page-footer .footer-container{
                width:100%;
            }

        }
        .page-footer .footer-container {
            width: auto;
        }

        .page-footer .footer-container .footer-left {
            width: 100%;
            padding: 0 60px;
        }

        .page-footer .footer-container .menu-item {
            padding: 0 15px;
        }

        .page-footer .footer-container .footer-right {
            width: auto;
        }

        .page-footer .footer-container .footer-right-inner {
            padding: 0 35px;
        }
    }

    @media screen and (max-width: 1500px) {
        .page-footer .overlay-filters .overlay-inner .close-filterpopup {
            left: 0;
        }

        .page-layout-pm_custom_home .overlay-filters .filters-wrapper {
            margin: 0 0 0 80px;
        }

        .page-layout-pm_custom_home .overlay-filters .search-bar {
            left: 80px;
            width: auto;
            transform: translate(0, 0);
        }

        .page-footer .overlay-filters .overlay-inner {
            left: 20px;
            right: 20px;
            transform: translate(0, -50%);
        }
    }

    @media screen and (max-width: 1360px) {
        .page-footer .footer-container {
            float: none;
        }

        .page-footer .footer-container .footer-left {
            display: block;
            height: auto;
            padding: 0;
        }

        .page-footer .footer-container .footer-menu {
            line-height: initial;
            overflow: auto;
        }

        .page-footer .footer-container .menu-item {
            padding: 25px 15px;
        }

        .page-footer .footer-container .menu-item .menuitem-icon {
            margin: 0 0 0 10px;
            vertical-align: top;
        }

        .page-footer .footer-container .footer-right {
            display: block;
            height: 200px;
            line-height: 200px;
        }
    }

    @media screen and (max-width: 1240px) {
        .page-footer .overlay-search .overlay-inner {
            width: auto;
            left: 20px;
            right: 20px;
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    @media screen and (max-width: 1024px) {
        .page-layout-custom_collection .page-footer .collection-filter-call {
            display: block;
        }

        .page-footer .footer-container .menu-item {
            font-size: 12px;
            padding: 25px 12px;
        }

        .page-footer .footer-container .menu-item.has-searchicon {
            display: none;
        }

        .page-footer .footer-container .menu-item .menuitem-icon {
            height: 18px;
        }

        .page-footer .overlay-search {
            background-color: #000000;
        }

        .page-footer .overlay-search .search-field {
            padding: 20px 15px;
            color: #ffffff;
            background-color: transparent;
        }

        .page-footer .filters-wrapper .swipe-bar {
            display: none !important;
        }

        .page-footer .overlay-search .search-close {
            display: none;
        }

        .page-footer .overlay-search .search-submit {
            display: block;

        }

        .page-footer .overlay-filters {
            background: #000000;
        }

        .page-footer .overlay-filters .overlay-inner {
            position: static;
            overflow: auto;
            transform: translate(0, 0);
        }

        .page-footer .overlay-container .overlay-menu {
            padding: 64px 0 0 0;
        }

        .page-footer .overlay-filters .overlay-inner .close-filterpopup {
            display: none;
        }

        .page-footer .overlay-filters .overlay-title {
            display: block;
        }

        .page-footer .overlay-filters .overlay-buttons {
            display: block;
        }
    }

    @media screen and (max-width: 767px) {
        .cms-page-view , .page-layout-custom_pdp , .page-layout-pm_custom_home , .dealers-index-index , .page-layout-custom_collection {
            .page-footer .footer-container .footer-left{
                width: 100%;
            }
        }
        .page-footer .footer-container .footer-left {
            width: 283px;
            padding: 53px 38px 40px;
        }

        .page-footer .footer-container .footer-menu {
            display: block;
            text-align: left;
        }

        .page-footer .footer-container .menu-item {
            font-size: 14px;
            float: none;
            padding: 0;
            line-height: 45px;
        }

        .page-footer .footer-container .menu-item .menuitem-icon {
            vertical-align: middle;
            margin: 0 0 4px 10px;
        }

        .page-layout-custom_collection .page-footer {
            background-color: #ffffff;
        }

        .page-layout-custom_collection .page-footer .footer-container .footer-left {
            background-color: #f0f1f1;
            width: 100%;
        }
    }

    @media screen and (max-width: 375px) {
        .page-footer .footer-container .footer-logo {
            width: 175px;
            margin: 0 20px 0 0;
        }
    }


    /* Page HOME CSS --------------------- */

    .page-layout-pm_custom_home .columns .column.main {
        padding-bottom: 0;
        overflow: hidden;
    }

    .page-layout-pm_custom_home .home-banner {
        position: relative;
        padding: 0 0 303px;
    }

    .page-layout-pm_custom_home .home-banner .banner-image {
        width: 100%;
    }

    .page-layout-pm_custom_home .home-banner .banner-inner {
        min-height: 600px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
    }

    .page-layout-pm_custom_home .banner-title {
        position: absolute;
        top: 24%;
        left: 12%;
        right: 12%;
        font-size: 50px;
        color: #000000;
        letter-spacing: 0.10px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.10px;
        margin: 0;
    }

    .page-layout-pm_custom_home .bolivia-section {
        position: absolute;
        top: 36%;
        left: 12%;
        height: 150px;
        background-color: #ffffff;
        background-image: url('../images/black-right-arrow.svg');
        background-position: center right 25px;
        background-size: 16px 8px;
        background-repeat: no-repeat;
    }

    .page-layout-pm_custom_home .bolivia-section a {
        display: block;
        outline: 0;
        text-decoration: none;
        padding: 28px 90px 41px 30px;
    }

    .page-layout-pm_custom_home .bolivia-section:hover {
        background-color: #000000;
        background-image: url('../images/white-right-arrow.svg');
    }

    .page-layout-pm_custom_home .bolivia-section h3 {
        font-size: 49px;
        letter-spacing: 0.05px;
        line-height: 59px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        margin: 0;
        color: #000000;
    }

    .page-layout-pm_custom_home .bolivia-section:hover h3 {
        color: #ffffff;
    }

    .page-layout-pm_custom_home .bolivia-section p {
        font-size: 16px;
        color: #58595B;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        margin: 0;
        line-height: 30px;
    }

    .page-layout-pm_custom_home .bolivia-section:hover p {
        color: #ffffff;
    }

    .page-layout-pm_custom_home .home-content {
        padding: 155px 20px 228px;
        background-color: #ffffff;
    }

    .page-layout-pm_custom_home .content-container {
        max-width: 1182px;
        margin: 0 auto;
        padding: 0;
    }

    .page-layout-pm_custom_home .section-title {
        font-size: 49px;
        color: #000000;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 59px;
        margin: 0 0 55px;
        letter-spacing: 0.05px;
    }

    .page-layout-pm_custom_home .content-container p {
        color: #707070;
        font-size: 16px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
    }

    .page-layout-pm_custom_home .btn-block {
        padding: 97px 0 0 0;
    }

    .page-layout-pm_custom_home .btn-block .btn-black {
        margin: 0 25px 0 0;
    }

    .page-layout-pm_custom_home .content-container .left-text {
        padding-right: 28px;
    }

    .page-layout-pm_custom_home .content-container .right-text {
        padding-left: 28px;
    }

    @media screen and (max-width: 1350px) {
        .page-layout-pm_custom_home .bolivia-section {
            left: 20px;
        }

        .page-layout-pm_custom_home .banner-title {
            left: 20px;
            right: 20px;
        }
    }

    @media screen and (max-width: 1200px) {
        .page-layout-pm_custom_home .home-banner .banner-inner {
            height: 1007px;
        }

        .page-layout-pm_custom_home .home-banner .banner-inner .banner-image {
            opacity: 0;
            visibility: hidden;
        }
    }

    @media screen and (max-width: 1024px) {
        .page-layout-pm_custom_home .banner-title {
            font-size: 35px;
            letter-spacing: 0.10px;
            line-height: 42px;
        }

        .page-layout-pm_custom_home .bolivia-section {
            top: 38%;
            height: 100px;
        }

        .page-layout-pm_custom_home .bolivia-section a {
            padding: 18px 95px 18px 25px;
        }

        .page-layout-pm_custom_home .bolivia-section h3 {
            font-size: 30px;
            letter-spacing: 0.05px;
            line-height: 37px;
        }

        .page-layout-pm_custom_home .home-content {
            padding: 100px 20px;
        }

        .page-layout-pm_custom_home .section-title {
            font-size: 30px;
            line-height: 37px;
            padding: 0 20px;
        }

        .page-layout-pm_custom_home .content-container .left-text {
            padding: 0 30px 0 35px;
            margin: 0 0 24px;
        }

        .page-layout-pm_custom_home .content-container .right-text {
            padding: 0 30px 0 35px;
        }
    }

    @media screen and (max-width: 767px) {
        .page-layout-pm_custom_home .home-content {
            padding: 90px 15px 80px;
        }

        .page-layout-pm_custom_home .btn-block {
            padding: 60px 5px 0;
        }

        .page-layout-pm_custom_home .home-banner .banner-inner {
            background-position: top left -250px;
        }
    }

    @media screen and (max-width: 575px) {
        .page-layout-pm_custom_home .btn-block {
            text-align: center;
        }

        .page-layout-pm_custom_home .btn-block .btn-black {
            margin: 0 0 20px 0;
        }
    }


    /* Page Collection ------------------- */

    .page-layout-custom_collection .collection-wrapper {
        position: relative;
    }

    .page-layout-custom_collection .filters-background {
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        width: 524px;
        background-color: #f0f1f1;
        right: 0;
        z-index: -1;
    }

    .page-layout-custom_collection .collection-inner {
        max-width: 1700px;
        margin: 0 auto;
        font-size: 0;
    }

    .page-layout-custom_collection .collection-filters {
        position: fixed;
        top: 187px;
        width: 250px;
        overflow: auto;
        background-color: #F0F1F1;
        padding: 0 0 0 35px;
    }

    .page-layout-custom_collection .product-wrapper {
        font-size: 16px;
        color: #000000;
        text-align: center;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
    }

    .page-layout-custom_collection .collection-products {
        display: inline-block;
        padding: 202px 0 89px 250px;
        vertical-align: top;
        width: 100%;
    }

    .page-layout-custom_collection .result-search-text {
        font-size: 35px;
        color: #a1a1a1;
        letter-spacing: 0.10px;
        line-height: 40px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        padding: 0 30px 40px 34px;
    }

    .page-layout-custom_collection .result-search-text .searched-item {
        font-size: 35px;
        color: #000000;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
    }

    .page-layout-custom_collection .filter-row h3 {
        font-size: 16px;
        line-height: 40px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        color: #000000;
        margin: 0;
        letter-spacing: 0.50px;
    }

    .page-layout-custom_collection .filter-row .filter-heading {
        font-weight: 600;
        letter-spacing: 0;
    }

    .page-layout-custom_collection .filter-row .filter-heading a {
        color: inherit;
        text-decoration: none;
    }

    .page-layout-custom_collection .filter-row .reset-text {
        display: none;
        color: inherit;
        text-decoration: none;
    }

    .page-layout-custom_collection .filter-row .accordion-title {
        text-decoration: none;
    }

    .page-layout-custom_collection .filter-row.active h3 {
        font-weight: 600;
    }

    .page-layout-custom_collection .filter-row.onlybold h3 {
        font-weight: 600;
    }

    .page-layout-custom_collection .filter-row.sort-wrapper {
        position: relative;
        margin: 45px 0;
    }

    .page-layout-custom_collection .filter-row.sort-wrapper {
        position: relative;
        margin: 45px 0;
    }

    .page-layout-custom_collection .sort-dropdown {
        position: absolute;
        font-size: 14px;
        opacity: 0;
        cursor: pointer;
    }

    .page-layout-custom_collection .filter-row.sort-wrapper .sort-dropdown {
        top: 10px;
        left: 0;
    }

    .page-layout-custom_collection .filter-row .sort-filter {
        position: relative;
        display: inline-block;
        font-size: 16px;
        line-height: 22px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        color: #000000;
        margin: 0;
        cursor: pointer;
        letter-spacing: 0.50px;
        text-decoration: none;
    }

    .page-layout-custom_collection .filter-row .sort-filter:after {
        position: absolute;
        left: -35px;
        top: 10px;
        width: 20px;
        height: 20px;
        content: " ";
        background-image: url('../images/sort-icon.svg');
        background-size: 20px 20px;
        background-repeat: no-repeat;
    }

    .page-layout-custom_collection .filter-dropdown {
        display: none;
        font-size: 14px;
        line-height: 30px;
        color: #2C2C2C;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 300;
        padding: 10px 0;
    }

    .page-layout-custom_collection .filter-row.active .filter-dropdown {
        display: block;
    }

    .page-layout-custom_collection .filter-dropdown .filter-choice {
        color: inherit;
        text-decoration: none;
    }

    .page-layout-custom_collection .filter-dropdown .filter-choice {
        position: relative;
        color: inherit;
        text-decoration: none;
    }

    .page-layout-custom_collection .filter-dropdown .filter-choice.selected {
        font-weight: bold;
    }

    .page-layout-custom_collection .filter-dropdown .filter-choice.selected::after {
        position: absolute;
        left: -12.5px;
        top: 10px;
        width: 8px;
        height: 8px;
        content: " ";
        margin-top: -3px;
        background-image: url('../images/black-close-icon.svg');
        background-size: 8px 8px;
        background-repeat: no-repeat;
    }

    .page-layout-custom_collection .floored-mobile {
        display: none;
        padding: 0 30px 30px 34px;
    }

    .page-layout-custom_collection .floored-heading {
        display: inline-block;
        vertical-align: middle;
        width: 128px;
    }

    .page-layout-custom_collection .floored-heading h2 {
        font-size: 35px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.10px;
        line-height: 35px;
        color: #000000;
    }

    .page-layout-custom_collection .mobile-sort {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        color: #000000;
        width: calc(100% - 128px);
        text-align: right;
    }

    .page-layout-custom_collection .mobile-sort-filter {
        position: relative;
        font-size: 14px;
        letter-spacing: 0.50px;
        line-height: 22px;
        padding: 0 0 0 31px;
        color: inherit;
        text-decoration: none;
    }

    .page-layout-custom_collection .mobile-sort-filter:before {
        position: absolute;
        left: 0;
        top: -2px;
        width: 20px;
        height: 20px;
        content: " ";
        background-image: url(../images/sort-icon.svg);
        background-size: 20px 20px;
        background-repeat: no-repeat;
    }

    .page-layout-custom_collection .mobile-sort-filter h3 {
        display: inline-block;
        font-size: inherit;
        font-weight: inherit;
    }

    .page-layout-custom_collection .mobile-sort .sort-dropdown {
        right: 0;
    }

    .page-layout-custom_collection .toolbar.toolbar-products,
    .page-layout-custom_collection .block.filter,
    .page-layout-custom_collection .products.products-grid,
    .page-layout-custom_collection .message.info.empty {
        display: none;
    }

    @media screen and (max-width: 1750px) {
        .page-layout-custom_collection .collection-filters {
            padding: 0 0 0 55px;
        }
    }

    @media screen and (max-width: 1200px) {
        .page-layout-custom_collection .collection-filters {
            width: 200px;
            top: 137px;
        }

        .page-layout-custom_collection .collection-products {
            width: 100%;
            padding: 150px 0 39px 200px;
        }
    }

    @media screen and (max-width: 1024px) {
        .page-layout-custom_collection .collection-filters {
            display: none;
        }

        .page-layout-custom_collection .filters-background {
            display: none;
        }

        .page-layout-custom_collection .collection-products {
            width: 100%;
            padding: 100px 0 60px 0;
        }

        .page-layout-custom_collection .floored-mobile {
            display: block;
        }
    }


    /* Product collection ---------------- */

    .product-data {
        width: 33.33%;
        float: left;
        padding: 0 20px;
        margin: 0 0 80px;
        text-align: left;
    }

    .product-data:nth-of-type(3n+1) {
        clear: left;
    }

    .product-data:nth-of-type(3n) {
        clear: right;
    }

    .product-data .product-layer {
        position: relative;
    }

    .product-data .product-feature {
        height: 320px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .product-data .product-link {
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
    }

    .product-data .product-feature img {
        display: none;
    }

    .product-data .product-label {
        display: inline-block;
        position: absolute;
        top: -15px;
        height: 30px;
        right: 30px;
        color: #ffffff;
        padding: 0 15px;
        letter-spacing: 0.50px;
        line-height: 30px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        background-color: #000000;
        text-transform: capitalize;
    }

    .product-data .product-title {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 200px;
        float: left;
        min-height: 66px;
        padding: 14px 19px;
        background-color: #ffffff;
        margin: -27px 0 0 0;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
        z-index: 99;
    }

    .category-products-collection .product-title {
        z-index: unset;
    }

    .product-data .product-feature:hover+.product-variant .product-title {
        background-color: #000000;
    }

    .product-data .product-title:hover {
        background-color: #000000;
    }

    .product-data .product-title h3 {
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        color: #000000;
        letter-spacing: 0.50px;
        line-height: 27px;
        font-size: 22px;
        margin: 0;
        text-transform: capitalize;
    }

    .product-data .product-feature:hover+.product-variant .product-title h3 {
        color: #ffffff;
    }

    .product-data .product-title:hover h3 {
        color: #ffffff;
    }

    .product-data .product-title span {
        display: block;
        color: #58595B;
        font-size: 14px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.50px;
        text-transform: capitalize;
    }

    .product-data .product-title h3 span {
        display: inline-block;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        color: #000000;
        letter-spacing: 0.50px;
        line-height: 27px;
        font-size: 22px;
        margin: 0;
        text-transform: uppercase;
    }

    .product-data .product-title p {
        display: none;
        color: #58595B;
        font-size: 10px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        margin: 0;
    }

    .product-data .product-feature:hover+.product-variant .product-title span {
        color: #ffffff;
    }

    .product-data .product-title:hover span {
        color: #ffffff;
    }

    .product-data .variant-list {
        display: inline-block;
        width: calc(100% - 200px);
        vertical-align: top;
        text-align: right;
        min-height: 80px;
        font-size: 0;
    }

    .product-data .product-variant li {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 40px;
    }

    .product-data .product-variant .color-choise {
        width: 40px;
        height: 40px;
        display: inline-block;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .product-data .product-variant .color-choise.selected:before {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 40px;
        height: 40px;
        content: " ";
        margin: -20px 0 0 -20px;
        background-color: rgba(0, 0, 0, 0.6);
        background-image: url('../images/white-check.svg');
        background-size: 40px 40px;
        background-position: center top 4px;
        background-repeat: no-repeat;
    }


    /*.product-data .product-variant li:hover .color-choise:before {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 40px;
    height: 40px;
    content: " ";
    margin: -20px 0 0 -20px;
    background-color: rgba(0,0,0,0.6);
    background-image: url('../images/white-check.svg');
    background-size: 40px 40px;
    background-position: center top 4px;
    background-repeat: no-repeat;
}*/

    @media screen and (max-width: 1750px) {
        .product-data {
            padding: 0 15px;
        }

        /*.product-data .product-title {
        display: block;
        width: 100%;
        margin: 0;
    }*/
        .product-data .variant-list {
            display: block;
            width: 100%;
        }
    }

    @media screen and (max-width: 1500px) {
        .product-data .product-feature {
            height: 250px;
        }
    }

    @media screen and (max-width: 1200px) {
        .product-data .product-feature {
            height: 200px;
        }
    }

    @media screen and (max-width: 1024px) {
        .product-data {
            margin: 0 0 20px;
        }

        .product-data .product-title h3,
        .product-data .product-title h3 span {
            font-size: 14px;
            line-height: initial;
        }

        .product-data .variant-list {
            display: none;
        }

        .product-data .product-title span {
            display: none;
        }

        .product-data .product-title p {
            display: block;
        }

        .product-data .product-title {
            display: inline-block;
            width: auto;
            min-height: auto;
            padding: 8px 19px;
            margin: -20px 0 0 0;
        }

        .product-data .product-label {
            font-size: 12px;
            top: 0;
            right: 0;
            height: 20px;
            line-height: 20px;
        }
    }

    @media screen and (max-width: 767px) {
        .product-data {
            width: 50%;
        }

        .product-data:nth-of-type(3n+1) {
            clear: initial;
        }

        .product-data:nth-of-type(3n) {
            clear: initial;
        }

        .product-data:nth-child(2n) {
            clear: right;
            padding: 0 20px 0 10px;
        }

        .product-data:nth-child(2n+1) {
            clear: left;
            padding: 0 10px 0 20px;
        }
    }

    @media screen and (max-width: 480px) {
        .product-data .product-feature {
            height: 130px;
        }
    }


    /* PDP page CSS ---------------------- */

    .page-layout-custom_pdp .pdp-wrapper {
        padding: 0 0 60px 0;
    }

    .page-layout-custom_pdp .product-main {
        position: relative;
    }

    .page-layout-custom_pdp .product-container {
        max-width: 1700px;
        margin: 0 auto;
    }

    .page-layout-custom_pdp .feature-background {
        position: absolute;
        width: 50%;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: #f0f1f1;
        z-index: -1;
    }

    .page-layout-custom_pdp .pdp-product {
        position: relative;
        display: table;
        width: 100%;
        font-size: 0;
        min-height: 100vh;
    }

    .page-layout-custom_pdp .pdp-product .pdp-feature {
        display: table-cell;
        width: 60%;
        vertical-align: top;
        padding: 170px 5% 54px 8%;
        background-color: #f0f1f1;
        color: #000000;
    }

    .page-layout-custom_pdp .pdp-product .feature-list {
        position: relative;
        padding: 50px 0 0 50px;
        z-index: 3;
    }

    .page-layout-custom_pdp .pdp-product .feature-list:before {
        position: absolute;
        left: 25px;
        top: 25px;
        content: " ";
        width: calc(100% - 100px);
        height: calc(100% - 50px);
        background-color: rgba(88, 89, 91, 0.08);
        z-index: -1;
    }

    .page-layout-custom_pdp .pdp-product .feature-list:after {
        position: absolute;
        left: 0;
        top: 0;
        content: " ";
        width: calc(100% - 150px);
        height: calc(100% - 50px);
        background-color: rgba(148, 152, 154, 0.08);
        z-index: -2;
    }

    .page-layout-custom_pdp .pdp-product .feature-image {
        width: 100%;
    }

    .page-layout-custom_pdp .btnsheet-mobile {
        display: none;
        margin: 20px 0 0 0;
    }

    .page-layout-custom_pdp .pdp-content .btn-white {
        display: none;
    }

    .page-layout-custom_pdp .btnsheet-desktop {
        margin: 20px 0 0 0;
    }

    .page-layout-custom_pdp .pdp-product .btn-pdp {
        display: inline-block;
        font-size: 14px;
        letter-spacing: 0.50px;
        font-family: sofia-pro, sans-serif;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 600;
        color: inherit;
        text-decoration: none;
    }

    .page-layout-custom_pdp .pdp-product .btn-sample {
        margin: 0 50px 0 0;
        padding: 0 0 0 35px;
        background-image: url('../images/request_sample.svg');
        background-size: 22px 22px;
        background-position: left center;
        background-repeat: no-repeat;
    }

    .page-layout-custom_pdp .pdp-product .btn-sheet {
        padding: 0 0 0 25px;
        background-image: url('../images/spec_sheet.svg');
        background-size: 14px 20px;
        background-position: left center;
        background-repeat: no-repeat;
    }

    .page-layout-custom_pdp .variant-inner .pdp-slick-buttons {
        display: none;
        position: absolute;
        right: -40px;
        top: 50%;
        width: 40px;
        height: 80px;
        font-size: 0;
        transform: translate(0, -50%);
    }

    .page-layout-custom_pdp .variant-inner .pdp-slick-button {
        width: 40px;
        height: 40px;
        background-color: #F0F1F1;
        outline: 0;
        border: 0;
    }

    .imagegallery-index-index .variant-inner .slick-disabled {
        opacity: 0.5;
        cursor: default;
    }

    .page-layout-custom_pdp .variant-inner .slick-next {
        background-image: url('../images/black-right-arrow.svg');
        background-size: 15px 8px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .page-layout-custom_pdp .variant-inner .slick-next:hover {
        background-color: #000000;
        background-image: url('../images/white-right-arrow.svg');
    }

    .page-layout-custom_pdp .variant-inner .slick-prev {
        background-image: url('../images/black-left-arrow.svg');
        background-size: 15px 8px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .page-layout-custom_pdp .variant-inner .slick-prev:hover {
        background-color: #000000;
        background-image: url('../images/white-left-arrow.svg');
    }

    .page-layout-custom_pdp .pdp-product .feature-variant {
      //  position: absolute;
        //left: 50%;
        left : unset;
        //bottom: 0;
        bottom: 0px;
        top: 680px;
        //transform: translate(-50%, 0);
        transform: translate(0%,0);
        text-align: center;
        width: 100%;
       // width: 30%;
    }
    .see_all_colors{
        text-align: right;
        float: right;
        color: #56595b;
        border-bottom: 2px solid grey;
        cursor: pointer;
        display: none;
    }
    .figCaptionColorName{
        margin: 0px;
        font-size: 12px;
        line-height: 20px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        text-transform: capitalize;
        font-weight: 500;
        color: #56595b;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

    }
    .page-layout-custom_pdp .pdp-product .variant-inner {
        position: relative;
        display: inline-block;
        text-align: left;
        //padding: 0 48px 53px 48px;
        padding: 0px;
        //background-color: #f0f1f1;
        width: 100%;
    }

    .page-layout-custom_pdp .pdp-product .colorways-text {
        padding: 15px 1px;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
    }

    .page-layout-custom_pdp .pdp-product .variant-list {
        margin: 0;
        padding: 0;
        max-width: 960px;
    }

    .page-layout-custom_pdp .pdp-product .variant-list .variant-item {
        display: inline-block;
        //width: 120px;
        width: 20%;
        max-width: 120px;
        padding: 5px 5px 5px 2px;
    }
    .page-layout-custom_pdp .pdp-product .variant-list .variant-item.hideSwatches{
        display:none;
    }
    .page-layout-custom_pdp .pdp-product .pdp-option {
        display: block;
        position: relative;
        text-decoration: none;
        outline: 0;
    }

    .page-layout-custom_pdp .pdp-product .pdp-option.active:after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 25px;
        height: 25px;
        background-color: #000000;
        background-image: url('../images/white-check.svg');
        background-size: 35px 30px;
        background-repeat: no-repeat;
        background-position: center;
    }

    .page-layout-custom_pdp .pdp-product .variant-list img {
        width: 100%;
    }

    .page-layout-custom_pdp .pdp-product .pdp-details {
        display: table-cell;
        width: 40%;
        padding: 155px 100px;
        vertical-align: top;
        background-color: #ffffff;
        text-align: center;
    }

    .page-layout-custom_pdp .pdp-product .pdp-content {
        display: inline-block;
        text-align: left;
        padding: 0 0 125px;
    }

    .page-layout-custom_pdp .pdp-product .pdp-content h3 {
        font-size: 40px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        color: #000000;
        margin: 0;
        text-transform: capitalize;
        letter-spacing: 0.50px;
    }

    .page-layout-custom_pdp .pdp-product .pdp-content h3 span {
        text-transform: uppercase;
    }

    .page-layout-custom_pdp .pdp-product .pdp-content .color-name {
        font-size: 24px;
        line-height: 30px;
        color: #000000;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        text-transform: capitalize;
        font-weight: 400;
        color: #000000;
    }

    .page-layout-custom_pdp .pdp-product .product-detail-table {
        font-size: 14px;
        line-height: 25px;
        color: #2c2c2c;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        padding: 66px 0 57px;
        max-width: 385px;
    }

    .page-layout-custom_pdp .pdp-product .product-detail-table .data-empty {
        display: none;
    }

    .page-layout-custom_pdp .pdp-product .product-detail-table .detail-label {
        color: #000000;
        font-weight: 600;
    }

    .page-layout-custom_pdp .pdp-product .product-detail-table .detail-value {
        text-transform: capitalize;
    }

    .page-layout-custom_pdp .pdp-product .available-text {
        display: block;
        color: #56595b;
        font-size: 14px;
        line-height: 30px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        margin: 5px 0 0 0;
    }

    .page-layout-custom_pdp .pdp-viewed .pdp-viewed-inner {
        padding: 89px 15px;
        max-width: 1450px;
        margin: 0 auto;
        overflow: auto;
    }

    .page-layout-custom_pdp .pdp-viewed .viewed-navigation {
        margin: 0 0 50px;
    }

    .page-layout-custom_pdp .pdp-viewed .viewed-list li {
        display: inline-block;
        padding: 0 20px;
    }

    .page-layout-custom_pdp .pdp-viewed .viewed-option {
        color: #95989A;
        font-size: 16px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-decoration: none;
    }

    .page-layout-custom_pdp .pdp-viewed .viewed-option.active {
        color: #2c2c2c;
        font-weight: 600;
    }

    .page-layout-custom_pdp .pdp-viewed .viewed-content {
        display: none;
    }

    .page-layout-custom_pdp .pdp-viewed .viewed-content.active {
        display: block;
    }

    .page-layout-custom_pdp .pdp-viewed .view-more {
        text-align: center;
        clear: both;
    }
    @media screen and (min-width: 1899px) {
        .page-layout-custom_pdp .pdp-product .pdp-feature {
            padding: 170px 5% 198px 8%;
        }
    }
    @media screen and (max-width: 1500px) {
        .page-layout-custom_pdp .pdp-product .product-detail-table {
            padding: 30px 0;
        }

        .page-layout-custom_pdp .pdp-product .colorways-text {
            padding: 35px 5px 10px;
        }
        .page-layout-custom_pdp .pdp-product .feature-variant{
            top:585px;
        }
    }

    @media screen and (min-width:319px) and (max-width: 767px) {
        .page-layout-custom_pdp .pdp-product .feature-variant {
            width: 100%;
            padding: 85px 1px 0px;
        }
        .page-layout-custom_pdp .pdp-product .variant-inner {
            width: 100% !important;
        }
    }
    @media screen and (min-width:767px) and (max-width: 1023px) {
        .page-layout-custom_pdp .pdp-product .variant-inner{
            width: 68%;
        }
    }
    @media screen and (max-width: 1024px) {
        .page-layout-custom_pdp .pdp-wrapper {
            padding: 0;
        }

        .page-layout-custom_pdp .pdp-product {
            display: block;
            padding: 0;
            min-height: auto;
        }

        .page-layout-custom_pdp .pdp-product .pdp-feature {
            display: block;
            width: 100%;
            padding: 130px 20px 0;
        }

        .page-layout-custom_pdp .feature-background {
            display: none;
        }

        .page-layout-custom_pdp .pdp-product .feature-list {
            max-width: 600px;
            margin: 0 auto;
            padding: 50px 0 0 0;
        }

        .page-layout-custom_pdp .pdp-product .feature-list:before {
            top: 35px;
            width: calc(100% - 50px);
        }

        .page-layout-custom_pdp .pdp-product .feature-list:after {
            left: 50px;
            top: 20px;
            width: calc(100% - 100px);
        }

        .page-layout-custom_pdp .pdp-product .pdp-details {
            display: block;
            width: 100%;
            padding: 40px 40px 60px;
            text-align: left;
        }

        .page-layout-custom_pdp .pdp-product .feature-variant {
            position: static;
            transform: translate(0, 0);
            overflow: hidden;
            text-align: left;
           // background-color: #f0f1f1;
            width: 100%;
            padding: 0px;
            background-color: unset;
        }

        .page-layout-custom_pdp .pdp-product .variant-inner {
            padding: 0 15px 20px 15px;
            display: inline-block;
            text-align: left;
            padding: 0 0 0px;
            width: 50%;
        }

        .page-layout-custom_pdp .variant-inner .pdp-slick-buttons {
            display: none !important;
        }

        .page-layout-custom_pdp .pdp-product .pdp-content h3 {
            font-size: 30px;
        }

        .page-layout-custom_pdp .pdp-product .pdp-content .color-name {
            font-size: 20px;
        }

        /* .page-layout-custom_pdp .pdp-product .available-text {
        display: none;
    } */
        .page-layout-custom_pdp .btnsheet-desktop {
            display: none;
        }

        .page-layout-custom_pdp .btnsheet-mobile {
            display: block;
            margin: 10px 0 40px;
        }

        .page-layout-custom_pdp .pdp-viewed .viewed-option {
            display: inline-block;
            font-size: 14px;
            padding: 19px 20px;
            background-color: #ffffff;
            letter-spacing: 0.50px;
        }

        .page-layout-custom_pdp .pdp-viewed .viewed-option.active {
            background-color: #f0f1f1;
        }

        .page-layout-custom_pdp .pdp-viewed .pdp-viewed-inner {
            padding: 0;
            background-color: #f0f1f1;
        }

        .page-layout-custom_pdp .pdp-viewed .viewed-list {
            background-color: #ffffff;
        }

        .page-layout-custom_pdp .pdp-viewed .view-more {
            display: none;
        }

        .page-layout-custom_pdp .pdp-viewed .viewed-list li {
            padding: 0;
        }

        .page-layout-custom_pdp .pdp-viewed .viewed-navigation {
            margin: 0 0 40px;
        }

        .page-layout-custom_pdp .product-data {
            padding: 0 20px;
        }

        .page-layout-custom_pdp .product-data .variant-list {
            display: block;
        }
    }

    @media screen and (max-width: 767px) {

        .page-layout-custom_pdp .pdp-product .variant-list .variant-item {
            width: 20%;
            max-width: 120px;
        }

        .page-layout-custom_pdp .pdp-product .pdp-option.active:after {
            width: 15px;
            height: 15px;
            background-size: 15px 15px;
        }

        .page-layout-custom_pdp .pdp-product .pdp-details {
            padding: 40px 20px 60px;
        }

        .page-layout-custom_pdp .pdp-product .btn-sample {
            background-size: 14px 14px;
            padding: 0 0 0 20px;
            margin: 0 15px 0 0;
        }

        .page-layout-custom_pdp .pdp-product .btn-sheet {
            background-size: 14px 14px;
            padding: 0 0 0 20px;
        }

        .page-layout-custom_pdp .pdp-viewed .viewed-option {
            font-size: 12px;
        }

        .page-layout-custom_pdp .product-data {
            float: none;
            width: 100%;
            max-width: 375px;
            margin: 0 auto 40px;
        }

        .page-layout-custom_pdp .pdp-product .feature-list {
            padding: 0;
        }

        .page-layout-custom_pdp .pdp-product .feature-list:before {
            top: -20px;
        }

        .page-layout-custom_pdp .pdp-product .feature-list:after {
            top: -40px;
        }
    }

    .pdp-overlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 99998;
    }

    .pdp-overlay .pdp-overlay-wrapper {
        position: fixed;
        max-width: 1260px;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 99999;
    }

    .pdp-overlay .pdpclose-overlay {
        position: absolute;
        width: 60px;
        height: 60px;
        top: 0;
        left: 0;
        outline: 0;
        border: 0;
        background-color: #F0F1F1;
        background-image: url('../images/black-close-icon.svg');
        background-size: 10px 10px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .pdp-overlay .pdpclose-overlay .close-icon:hover {
        background-color: #000000;
        background-image: url('../images/white-close-icon.svg');
    }

    .pdp-overlay .pdp-overlay-section {
        padding: 67px 65px 117px;
        background-color: #ffffff;
        margin: 0 0 0 60px;
        font-size: 0;
    }

    .pdp-overlay .pdp-overlay-details {
        display: inline-block;
        vertical-align: top;
        width: 45%;
        padding: 0 40px;
    }

    .pdp-overlay .pdp-overlay-form {
        display: inline-block;
        vertical-align: top;
        width: 55%;
        padding: 0 40px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.50px;
    }

    .pdp-overlay .pdp-overlay-form label.error {
        display: none !important;
    }

    .pdp-overlay .pdp-overlay-heading {
        font-size: 40px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.05px;
        line-height: 45px;
        max-width: 165px;
        margin: 0 0 33px;
    }

    .pdp-overlay .pdp-overlay-description {
        font-size: 14px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        color: #58595B;
        max-width: 301px;
        margin: 0;
    }

    .pdp-overlay .pdp-overlay-colorway {
        margin: 50px 0 0 0;
    }

    .pdp-overlay .pdp-overlay-colorway-heading {
        font-size: 16px;
        color: #000000;
        line-height: 19px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        margin: 0;
    }

    .pdp-overlay .pdp-overlay-variant-list {
        margin: 15px 0 0 -5px;
        overflow: auto;
    }

    .pdp-overlay .pdp-overlay-variant-list li {
        width: 120px;
        height: 85px;
        float: left;
        padding: 5px;
    }

    .pdp-overlay .pdp-overlay-color-choise {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
    }

    .pdp-overlay .pdp-overlay-color-choise.selected:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 36px;
        height: 36px;
        content: " ";
        background-color: #000000;
        background-image: url('../images/white-check.svg');
        background-size: 40px 40px;
        background-position: center top 2px;
        background-repeat: no-repeat;
    }

    .pdp-overlay .pdp-overlay-form input {
        display: block;
        width: 100%;
        height: 50px;
        padding: 15px;
        margin: 0 0 30px;
        color: #000000;
        background-color: #F0F1F1;
        border: none;
    }

    .pdp-overlay .pdp-overlay-form input.error {
        border: 1px solid #ff0000;
    }

    .pdp-overlay .pdp-overlay-form input:focus {
        outline: 0;
        background-color: #ffffff;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    }

    .pdp-overlay .pdp-overlay-form textarea {
        display: block;
        width: 100%;
        height: 150px;
        padding: 15px;
        margin: 0 0 30px;
        color: #000000;
        background-color: #F0F1F1;
        border: none;
        resize: none;
    }

    .pdp-overlay .pdp-overlay-form textarea.error {
        border: 1px solid #ff0000;
    }

    .pdp-overlay .pdp-overlay-form textarea:focus {
        outline: 0;
        background-color: #ffffff;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    }

    .pdp-overlay .padpfilters-submit {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 80px;
        height: 80px;
        background-color: #000000;
        border: 0;
        outline: 0;
    }

    .pdp-overlay .padpfilters-submit .arrow-icon {
        width: 16px;
        height: 8px;
    }

    .pdp-overlay .pdpclose-overlay-mobile {
        display: none;
        position: absolute;
        top: 20px;
        left: 50%;
        content: " ";
        font-size: 14px;
        color: #000000;
        transform: translate(-50%, 0);
        letter-spacing: 1px;
        padding: 0 0 0 20px;
        background-image: url('../images/black-close-icon.svg');
        background-size: 10px 10px;
        background-position: left top 4px;
        background-repeat: no-repeat;
    }

    @media screen and (min-width: 1025px) {
        .pdp-overlay .pdp-overlay-submit-wrapper {
            display: none !important;
        }

        .pdp-overlay .pdp-overlay-section {
            max-height: calc(100vh - 100px);
            overflow: auto;
        }
    }

    @media screen and (max-width: 1200px) {
        .pdp-overlay .pdp-overlay-section {
            padding: 65px 20px 100px;
        }

        .pdp-overlay .pdp-overlay-details {
            padding: 0 25px;
        }

        .pdp-overlay .pdp-overlay-form {
            padding: 0 25px;
        }
    }

    @media screen and (max-width: 1024px) {
        .pdp-overlay {
            position: absolute;
        }

        .pdp-overlay .pdp-overlay-wrapper {
            position: relative;
            top: 0;
            left: 0;
            height: 100%;
            transform: translate(0, 0);
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            background-color: #ffffff;
        }

        .pdp-overlay .pdp-overlay-section {
            padding: 100px 30px 35px;
            margin: 0;
        }

        .pdp-overlay .pdp-overlay-details {
            display: block;
            width: 100%;
            padding: 0;
        }

        .pdp-overlay .pdp-overlay-form {
            display: block;
            width: 100%;
            padding: 0;
        }

        .pdp-overlay .pdp-overlay-form input {
            margin: 0 0 20px;
        }

        .pdp-overlay .pdp-overlay-heading {
            font-size: 35px;
            max-width: 100%;
        }

        .pdp-overlay .pdp-overlay-colorway {
            margin: 30px 0 35px;
        }

        .pdp-overlay .pdpclose-overlay {
            display: none;
        }

        .pdp-overlay .padpfilters-submit {
            display: none !important;
        }

        .pdp-overlay .pdpclose-overlay-mobile {
            display: inline-block;
        }

        .pdp-overlay .pdp-overlay-submit-wrapper {
            display: block;
        }
    }


    /* Gallery page ---------------------- */

    .imagegallery-index-index .gallery-wrapper {
        padding: 139px 0 126px;
    }

    .imagegallery-index-index .gallery-wrapper .gallery-inner {
        position: relative;
        max-width: 1640px;
        margin: 0 auto;
        overflow: hidden;
        min-height: 250px;
    }

    .imagegallery-index-index .gallery-wrapper .gallery-item {
        width: 33.33%;
        padding: 20px;
    }

    .imagegallery-index-index .gallery-wrapper .gallery-popup-call {
        display: block;
    }

    .imagegallery-index-index .gallery-wrapper .gallery-item.get-inspired {
        position: relative;
        height: 344px;
        text-align: center;
    }

    .imagegallery-index-index .gallery-wrapper .gallery-item img {
        width: 100%;
    }

    .imagegallery-index-index .gallery-wrapper .gallery-item .get-inspired-text {
        position: relative;
        top: 50%;
        display: inline-block;
        font-size: 50px;
        line-height: 45px;
        max-width: 210px;
        text-align: left;
        letter-spacing: 0.10px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        transform: translate(0, -50%);
    }

    .imagegallery-index-index .gallery-overlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 99998;
    }

    .imagegallery-index-index .gallery-overlay-wrapper {
        position: fixed;
        max-width: 980px;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 99999;
    }

    .imagegallery-index-index .galleryclose-overlay {
        position: absolute;
        width: 60px;
        height: 60px;
        top: 0;
        left: 0;
        outline: 0;
        border: 0;
        background-color: #F0F1F1;
        background-image: url('../images/black-close-icon.svg');
        background-size: 10px 10px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .imagegallery-index-index .galleryclose-overlay:hover {
        background-color: #000000;
        background-image: url('../images/white-close-icon.svg');
    }

    .imagegallery-index-index .galleryclose-overlay .close-icon {
        width: 10px;
        height: 10px;
    }

    .imagegallery-index-index .gallery-overlay-section {
        height: 100%;
        background: #ffffff;
        margin: 0 40px 0 60px;
    }

    .imagegallery-index-index .mobile-layout {
        display: none;
    }

    .imagegallery-index-index .popup-content {
        font-size: 0;
    }

    .imagegallery-index-index .gallery-image {
        width: 63%;
    }

    .imagegallery-index-index .gallery-image img {
        width: 100%;
    }

    .imagegallery-index-index .gallery-details {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        padding: 20px 0;
        width: 37%;
    }

    .imagegallery-index-index .gallery-details-wrapper {
        display: block;
        width: 100%;
        height: 100%;
        padding: 17px 39px 105px;
        overflow: hidden auto;
    }

    .imagegallery-index-index .gallery-caption {
        font-size: 16px;
        color: #58595B;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
    }

    .imagegallery-index-index .gallery-caption strong {
        color: #000000;
    }

    .imagegallery-index-index .gallery-tag {
        display: inline-block;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #2C2C2C;
        line-height: 30px;
        margin: 15px 0 0 0;
    }

    .imagegallery-index-index .assigned-product-details {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #F0F1F1;
        background-image: url('../images/black-right-arrow.svg');
        background-size: 15px 8px;
        background-position: center right 25px;
        background-repeat: no-repeat;
    }

    .imagegallery-index-index .assigned-product-details a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 15px 50px 24px 38px;
        text-decoration: none !important;
        outline: 0 !important;
    }

    .imagegallery-index-index .assigned-product-details:hover {
        background-color: #000000;
        background-image: url('../images/white-right-arrow.svg');
    }

    .imagegallery-index-index .assigned-product-name {
        font-size: 30px;
        color: #000000;
        letter-spacing: 0.05px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        margin: 0;
        text-transform: capitalize;
    }

    .imagegallery-index-index .assigned-product-details:hover .assigned-product-name {
        color: #ffffff;
    }

    .imagegallery-index-index .assigned-product-collection {
        font-size: 16px;
        color: #58595B;
        line-height: 30px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        text-transform: capitalize;
    }

    .imagegallery-index-index .assigned-product-details:hover .assigned-product-collection {
        color: #ffffff;
    }

    .imagegallery-index-index .gallery-overlay .popup-content {
        position: relative;
        display: block;
    }

    .imagegallery-index-index .gallery-overlay .overlay-slick-buttons {
        position: absolute;
        right: 0;
        top: 50%;
        width: 40px;
        height: 80px;
        font-size: 0;
        transform: translate(0, -50%);
    }

    .imagegallery-index-index .gallery-overlay .overlay-slick-button {
        width: 40px;
        height: 40px;
        background-color: #F0F1F1;
        outline: 0;
        border: 0;
    }

    .imagegallery-index-index .gallery-overlay .slick-disabled {
        opacity: 0.5;
        cursor: default;
    }

    .imagegallery-index-index .gallery-overlay .slick-next {
        background-image: url('../images/black-right-arrow.svg');
        background-size: 15px 8px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .imagegallery-index-index .gallery-overlay .slick-next:hover {
        background-color: #000000;
        background-image: url('../images/white-right-arrow.svg');
    }

    .imagegallery-index-index .gallery-overlay .slick-prev {
        background-image: url('../images/black-left-arrow.svg');
        background-size: 15px 8px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .imagegallery-index-index .gallery-overlay .slick-prev:hover {
        background-color: #000000;
        background-image: url('../images/white-left-arrow.svg');
    }

    .imagegallery-index-index .room-selection-wrapper {
        position: fixed;
        bottom: 100px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 300px;
        height: 60px;
        background-color: #ffffff;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
        padding: 15px;
    }

    .imagegallery-index-index .room-selection-filter {
        display: block;
    }

    .imagegallery-index-index .room-selection-filter h3 {
        font-size: 22px;
        letter-spacing: 0.50px;
        color: #000000;
        margin: 0;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
    }

    .imagegallery-index-index .room-selection-filter h3:after {
        position: absolute;
        right: 0;
        top: 0;
        width: 60px;
        height: 60px;
        content: " ";
        background-color: #f0f1f1;
        background-image: url('../images/black-dropdown-arrow.svg');
        background-size: 15px 8px;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .imagegallery-index-index .room-selection-wrapper .filter-room {
        position: absolute;
        font-size: 14px;
        opacity: 0;
        cursor: pointer;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        width: 100%;
        display: block;
        z-index: 55;
        -webkit-appearance: none;
    }

    .imagegallery-index-index .cross-room-filters {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 60px;
        height: 60px;
        content: " ";
        background-color: #f0f1f1;
        background-image: url(../images/black-close-icon.svg);
        background-size: 16px 16px;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 56;
    }

    .imagegallery-index-index .filter-active .cross-room-filters {
        display: block;
    }

    .imagegallery-index-index .room-selection-wrapper.filter-active h3 {
        background-image: none;
    }

    .imagegallery-index-index .mobile-room-selection-wrapper {
        position: absolute;
        display: none;
        width: 20px;
        height: 18px;
        right: 25px;
        background-image: url('../images/filter.svg');
        background-size: 20px 18px;
        background-position: center;
        background-repeat: no-repeat;
        top: 130px;
        z-index: 85;
    }

    .imagegallery-index-index .filter-room {
        position: absolute;
        font-size: 14px;
        opacity: 0;
        cursor: pointer;
        top: 0;
        height: 100%;
        right: 0;
        display: block;
    }

    @media screen and (max-width: 1200px) {
        .imagegallery-index-index .gallery-wrapper .gallery-item {
            padding: 10px;
        }
    }

    @media screen and (max-width: 767px) {
        .imagegallery-index-index .gallery-wrapper {
            padding: 95px 0 0;
        }

        .imagegallery-index-index .gallery-wrapper .gallery-item {
            width: 100%;
            padding: 0;
            margin: 0 0 40px;
        }

        .imagegallery-index-index .gallery-wrapper .gallery-item.get-inspired {
            height: auto;
            padding: 0 38px;
            text-align: left;
        }

        .imagegallery-index-index .gallery-wrapper .gallery-item .get-inspired-text {
            font-size: 35px;
            line-height: 35px;
            letter-spacing: 0.10px;
            transform: translate(0, 0);
        }

        .imagegallery-index-index .gallery-overlay {
            display: none !important;
        }

        .imagegallery-index-index .mobile-layout {
            display: block;
        }

        .imagegallery-index-index .gallery-image {
            display: none;
            width: 100%;
        }

        .imagegallery-index-index .gallery-details {
            position: static;
            padding: 0;
            width: 100%;
        }

        .imagegallery-index-index .gallery-details-wrapper {
            padding: 17px 38px 0;
            overflow: hidden;
        }

        .imagegallery-index-index .gallery-tag {
            display: none;
        }

        .imagegallery-index-index .gallery-caption {
            font-size: 14px;
            line-height: 20px;
        }

        .imagegallery-index-index .assigned-product-details {
            position: relative;
            background-color: #ffffff;
            margin: -20px 17px 0;
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
            padding: 12px 50px 18px 21px;
        }

        .imagegallery-index-index .assigned-product-details a {
            padding: 12px 50px 18px 21px;
        }

        .imagegallery-index-index .mobile-room-selection-wrapper {
            display: inline-block;
        }

        .imagegallery-index-index .room-selection-wrapper {
            display: none;
        }
    }


    /* Contact page CSS ------------------ */

    .contactus-index-index .form.contact {
        display: none;
    }

    .contact-wrapper .contact-inner {
        font-size: 0;
        height: 100vh;
    }

    .contact-wrapper .contact-left {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 27%;
        height: 100%;
        padding: 124px 0;
        background-color: #f0f1f1;
    }

    .contact-wrapper .contact-right {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 73%;
        height: 100%;
        padding: 124px 15px;
    }

    .contact-wrapper .contact-details {
        max-width: 250px;
        padding: 40px 55px;
        color: #000000;
        background-color: #ffffff;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 40%;
        right: 100px;
        transform: translate(0, -50%);
    }
    .contact-wrapper .contact-details_2 {
        max-width: 250px;
        z-index : 1;
        padding: 42px 40px;
        color: #000000;
        background-color: #ffffff;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 78%;
        right: 100px;
        transform: translate(0, -50%);
    }

    .contact-wrapper .come-visit {
        color: #000000;
        font-size: 22px;
        letter-spacing: 0.50px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
    }

    .contact-wrapper address {
        font-size: 14px;
        color: #58595B;
        line-height: 20px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        margin: 16px 0 29px 0;
    }

    .contact-wrapper .contact-email {
        display: block;
        font-size: 14px;
        color: inherit;
        letter-spacing: 0.50px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        text-decoration: none;
    }

    .contact-wrapper .contact-tel {
        display: block;
        font-size: 14px;
        color: inherit;
        letter-spacing: 0.50px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        text-decoration: none;
    }

    .contact-wrapper .contact-direction {
        display: none;
        font-size: 14px;
        color: inherit;
        letter-spacing: 0.50px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        text-decoration: none;
        margin: 30px 0 0 0;
        padding: 0 35px 0 0;
        background-image: url('../images/black-right-arrow.svg');
        background-size: 15px 8px;
        background-position: center right;
        background-repeat: no-repeat;
    }

    .contact-wrapper .form-wrapper {
        position: absolute;
        top: 50%;
        left: 135px;
        right: 0;
        transform: translate(0, -50%);
    }

    .contact-wrapper .dealer-search {
        display: block;
        width: 100%;
        font-size: 49px;
        color: #000000;
        letter-spacing: 0.05px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        height: 75px;
        padding: 5px 0;
        border: none;
        outline: 0;
        line-height: 67px;
        margin: 0 0 25px 0;
    }

    .contact-wrapper .contact-form {
        padding: 0 15px;
    }

    .contact-wrapper .contact-form .error-display-field {
        display: none;
        font-size: 16px;
        letter-spacing: 0.05px;
        color: #ff0000;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        margin: 0 0 35px 0;
    }

    .contact-wrapper .contact-form .form-field-wrapper {
        display: none;
    }

    @media screen and (min-width: 1200px) {
        .contact-wrapper .contact-form .col-xl-4 {
            flex: 0 0 49.333333%;
            max-width: 49.333333%;
        }

        .contact-wrapper .contact-form .btn-black {
            margin: 25px 0 0 0;
        }
    }

    .contact-wrapper .contact-form .g-recaptcha {
        margin: 25px 0 0 0;
    }

    @media screen and (min-width: 991px) and (max-width: 1280px) {
        .contact-wrapper .contact-form .g-recaptcha {
            transform: scale(0.85);
            -webkit-transform: scale(0.85);
            transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
        }
    }

    .contact-wrapper .contact-form .form-field {
        font-size: 22px;
        letter-spacing: 0.50px;
        color: #000000;
        height: 60px;
        outline: 0;
        border: 0;
        padding: 5px 15px;
        width: 100%;
        background-color: #F0F1F1;
    }

    .contact-wrapper .contact-form .form-field:focus {
        outline: 0;
        background-color: #ffffff;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    }

    .contact-wrapper .contact-form .form-field.error {
        border: 1px solid #ff0000;
    }

    .contact-wrapper .contact-form label.error {
        display: none !important;
    }

    .contact-wrapper .contact-response {
        display: none;
    }

    .contact-wrapper .contact-response h4 {
        font-size: 49px;
        letter-spacing: 0.05px;
        color: #000000;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        margin: 0 0 15px;
    }

    .contact-wrapper .contact-response p {
        font-size: 16px;
        color: #58595B;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        margin: 0;
    }

    .contact-wrapper .mobile-ask-title {
        display: none;
        padding: 115px 38px 136px;
        background-color: #F0F1F1;
    }

    .contact-wrapper .mobile-ask-title h4 {
        font-size: 35px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.10px;
        line-height: 40px;
        max-width: 185px;
        color: #000000;
        margin: 0;
    }

    .contact-wrapper .mobile-ask-title p {
        font-size: 16px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.10px;
        color: #94989A;
        margin: 0;
    }

    @media screen and (max-width: 1600px) {
        .contact-wrapper .contact-details {
            padding: 18px;
            right: 62px;
            max-width: 240px;
            margin-top: -90px;
            position: absolute;
            top: 50%;
        }

        .contact-wrapper .contact-details_2 {
            padding: 18px;
            z-index : 1;
            right: 62px;
            max-width: 240px;
            margin: 0px;
            position: absolute;
            top: 75%;
        }
    }
    @media screen and (max-width: 767px) {
        .contact-wrapper .contact-details_2{
                position: relative !important;
                bottom: -21px;
                right: 0px !important;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        .contact-wrapper .contact-details_2{
            top: 67% !important;
        }
    }
    @media screen and (min-width: 1023px) and (max-width: 1030px) {
        .contact-wrapper .contact-details_2{
            top: 65% !important;
        }
    }

    @media screen and (max-width: 1200px) {
        .contact-wrapper .form-wrapper {
            left: 35px;
        }

        .contact-wrapper .contact-left {
            width: 40%;
        }

        .contact-form .contact-form-submit {
            margin: 25px 0 0 0;
        }

        .contact-wrapper .contact-right {
            width: 60%;
        }
    }

    @media screen and (max-width: 991px) {
        .contact-wrapper .contact-form .form-field {
            margin: 25px 0 0 0;
        }

        .contact-wrapper .contact-details {
            padding: 20px;
        }
        .contact-wrapper .contact-details_2 {
            padding: 20px;
        }
    }

    @media screen and (max-width: 767px) {
        .contact-wrapper .contact-form .form-field {
            font-size: 16px;
        }

        .contact-wrapper .contact-inner {
            height: auto;
        }

        .contact-wrapper .contact-left {
            display: block;
            width: 100%;
            padding: 0 38px 50px;
        }

        .contact-wrapper .contact-right {
            display: none;
            width: 100%;
            padding: 50px 15px;
        }

        .contact-wrapper .contact-details {
            position: static;
            transform: translate(0, 0);
            margin: 0;
        }
        .contact-wrapper .contact-details_2 {
            position: static;
            transform: translate(0, 0);
            margin: 0;
        }

        .contact-wrapper .form-wrapper {
            position: static;
            transform: translate(0, 0);
        }


        .contact-wrapper .mobile-ask-title {
            display: block;
        }
    }


    /* Dealer page CSS ------------------- */
@media screen and (min-width:1024px){

    .dealers-index-index{
        //.page-footer {
        //    overflow: auto;
        //    position: absolute;
        //    bottom: 0;
        //    width: 100%;
        //}
        .dealers-base .dealers-button {
            position: relative;
            top: -30px;
            right: 0;
            display: inline-block;
            float: right;
        }
    }

}
@media screen and (min-width:768px) and (max-width: 1023px){

    .dealers-index-index{
        //.page-footer {
        //    overflow: auto;
        //    position: absolute;
        //    bottom: 0;
        //    width: 100%;
        //}
        .dealers-base .dealers-button {
            position: relative;
            top: -30px;
            right: 0;
            display: inline-block;
            float: right;
        }
    }

}

@media screen and (max-width:767px){
    .dealers-index-index .dealers-base .dealers-button {
        display:block;
        margin: auto;
        position: absolute;
        bottom: 24px;
        top:unset;
        left: 0;
        float: none;
        right: 0;
        z-index:999;
        width:90%;
        margin-left: auto;
        margin-right: auto;
    }
}


    .dealers-base {
        position: relative;
    }

    .dealers-base #dealers-map {
        width: 100%;
        height: 600px;
    }

    .dealers-base .dealers-wrapper {
        position: relative;
        max-width: 1600px;
        margin: 0 auto;
    }

    .dealers-base .dealers-button {
        position: absolute;
        top: -30px;
        right: 0;
    }

    .dealers-base .autocomplete-search {
        position: absolute;
        top: -30px;
        left: 0;
        width: 300px;
        height: 60px;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
        z-index: 99;
    }

    .dealers-base .autocomplete-button {
        position: absolute;
        right: 0;
        top: 0;
        width: 60px;
        height: 60px;
        border: none;
        outline: 0;
        background-color: #f0f1f1;
        background-image: url('../images/search.svg');
        background-position: center;
        background-size: 20px 20px;
        background-repeat: no-repeat;
    }

    .dealers-base .autocomplete-button:hover {
        background-color: #000000;
        background-image: url('../images/white-search.svg');
    }

    .dealers-base .autocomplete-search input {
        width: 100%;
        height: 60px;
        border: none;
        outline: 0;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.50px;
        line-height: 30px;
        font-size: 22px;
        padding: 10px 75px 10px 15px;
        color: #000000;
    }

    .dealers-base .dealers-section {
        padding: 87px 0;
        font-size: 0;
        position: relative;
    }

    .dealers-base .dealers-options {
        display: inline-block;
        vertical-align: top;
        width: 18%;
        padding: 32px 0;
        font-size: 22px;
        letter-spacing: 0.10px;
        line-height: 25px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
    }

    .dealers-base .dealers-selection {
        position: fixed;
        padding: 25px;
        bottom: 165px;
        background-color: #ffffff;
        z-index: 99;
    }

    .dealers-base .dealers-options .dealers-count {
        outline: 0;
        color: #58595B;
        background-color: #ffffff;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 0 15px 0 0;
        background-image: url('../images/down-arrow-grey.svg');
        background-position: center right;
        background-size: 10px 5px;
        background-repeat: no-repeat;
    }

    .dealers-base .dealers-data {
        display: inline-block;
        vertical-align: top;
        width: 82%;
        padding: 0 29px;
        font-size: 0;
    }

    .dealers-base .dealers-col {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 25%;
        padding: 57px 59px;
        cursor: pointer;
    }

    .dealers-base .product-label {
        display: none;
        position: absolute;
        top: -15px;
        height: 30px;
        right: 30px;
        color: #ffffff;
        padding: 0 15px;
        letter-spacing: 0.50px;
        line-height: 30px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        background-color: #000000;
        text-transform: capitalize;
    }

    .dealers-base .dealers-col:hover .product-label {
        display: inline-block;
    }

    .dealers-base .dealers-stark {
        background-color: #F0F1F1;
    }

    .dealers-base .dealers-col h3 {
        font-size: 22px;
        color: #000000;
        letter-spacing: 0.10px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        margin: 0;
        text-transform: capitalize;
    }

    .dealers-base .dealers-col p {
        font-size: 16px;
        color: #58595B;
        line-height: 30px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        margin: 0 0 25px;
        text-transform: capitalize;
    }

    .dealers-base .dealers-col address {
        font-size: 14px;
        color: #2C2C2C;
        line-height: 20px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 300;
        margin: 0 0 30px;
    }

    .dealers-base .dealers-col a {
        display: inline-block;
        font-size: 14px;
        color: #000000;
        letter-spacing: 0.50px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        text-decoration: none;
    }

    .dealers-base .dealers-col a:last-of-type {
        margin: 10px 0 0 0;
    }

    .dealers-base .slick-slide {
        margin: 0 5px;
    }

    .dealers-base .slick-list {
        margin: 0 -5px;
    }

    @media screen and (min-width: 768px) {
        .dealers-base .dealers-col:hover {
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
        }

        .dealers-base .dealers-col.highlighted {
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
        }
    }

    @media screen and (max-width: 1600px) {
        .dealers-base .dealers-col {
            width: 33.33%;
        }
    }

    @media screen and (max-width: 1200px) {
        .dealers-base .dealers-section {
            padding: 50px 0;
        }

        .dealers-base .dealers-options {
            width: 100%;
            display: block;
            text-align: center;
            padding: 0 15px 50px 15px;
        }

        .dealers-base .dealers-selection {
            position: static;
            padding: 0;
        }

        .dealers-base .dealers-data {
            width: 100%;
            display: block;
        }

        .dealers-base .dealers-col {
            padding: 40px;
        }
    }

    @media screen and (max-width: 1024px) {
        .dealers-base .autocomplete-search {
            left: 50%;
            top: 100px;
            transform: translate(-50%, 0);
        }

        .dealers-base .dealers-button {
            display: none;
        }

        .dealers-base .dealers-wrapper {
            position: static;
        }

        .dealers-base .product-label {
            font-size: 12px;
            top: 0;
            right: 0;
            height: 20px;
            line-height: 20px;
        }
    }

    @media screen and (max-width: 991px) {
        .dealers-base .dealers-col {
            width: 50%;
        }
    }

    @media screen and (max-width: 767px) {
        .dealers-base .dealers-section {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 40px 0;
        }

        .dealers-base .dealers-options {
            display: none;
        }

        .dealers-base .dealers-data {
            padding: 0;
            overflow: hidden;
        }

        .dealers-base .dealers-col {
            background-color: #ffffff;
        }
    }


    /* Collection dealers on search CSS -- */

    .dealers-search-data {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        font-size: 0;
        margin: 0 0 60px;
    }

    .dealers-search-data .dealers-col {
        display: inline-block;
        vertical-align: top;
        width: 33.33%;
        padding: 0 15px;
        margin: 0 0 40px;
    }

    .dealers-search-data .dealers-col-inner {
        padding: 30px 30px;
        background-color: #ffffff;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    }

    .dealers-search-data .dealers-col h3 {
        font-size: 22px;
        color: #000000;
        letter-spacing: 0.10px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        margin: 0;
    }

    .dealers-search-data .dealers-col p {
        font-size: 16px;
        color: #58595B;
        line-height: 30px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        margin: 0 0 25px;
    }

    .dealers-search-data .dealers-col address {
        font-size: 14px;
        color: #2C2C2C;
        line-height: 20px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 300;
        margin: 0 0 30px;
    }

    .dealers-search-data .dealers-col a {
        font-size: 14px;
        color: #000000;
        letter-spacing: 0.50px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        text-decoration: none;
    }

    .dealers-search-data .slick-slide {
        margin: 0 5px;
    }

    .dealers-search-data .slick-list {
        margin: 0 -5px;
    }

    @media screen and (max-width: 1024px) {
        .dealers-search-data .autocomplete-search {
            display: none;
        }

        .dealers-search-data .dealers-button {
            display: none;
        }
    }

    @media screen and (max-width: 991px) {
        .dealers-search-data .dealers-col {
            width: 50%;
        }
    }

    @media screen and (max-width: 767px) {
        .dealers-search-data .dealers-col {
            padding: 0 10px 0 20px;
        }

        .dealers-search-data .dealers-col:nth-child(2n) {
            padding: 0 20px 0 10px;
        }
    }

    @media screen and (max-width: 600px) {
        .dealers-search-data .dealers-col {
            width: 100%;
            padding: 0 20px;
        }

        .dealers-search-data .dealers-col:nth-child(2n) {
            padding: 0 20px;
        }
    }


    /* Binding page CSS ------------------ */

    .cms-binding-fabrication .binding-banner {
        width: 100%;
        background-color: #F0F1F1;
    }

    .cms-binding-fabrication .binding-banner-inner {
        display: table;
        max-width: 1454px;
        margin: 0 auto;
        width: 100%;
        height: 725px;
        padding: 0 27px;
    }

    .cms-binding-fabrication .binding-banner-text {
        display: table-cell;
        vertical-align: middle;
    }

    .cms-binding-fabrication .binding-banner-text-inner {
        display: block;
        max-width: 785px;
        color: #000000;
        font-size: 40px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.10px;
        line-height: 45px;
    }

    .cms-binding-fabrication .content-row.matched-row {
        background-color: #f0f1f1;
    }

    .cms-binding-fabrication .content-row-inner {
        max-width: 1454px;
        margin: 0 auto;
        font-size: 0;
    }

    .cms-binding-fabrication .content-col {
        position: relative;
        display: inline-block;
        width: calc(calc(100% - 592px) / 2);
        padding: 0 27px;
        vertical-align: top;
    }

    .cms-binding-fabrication .matched-row .image-col img {
        vertical-align: bottom;
    }

    .cms-binding-fabrication .unmatched-row .image-col img {
        vertical-align: top;
    }

    .cms-binding-fabrication .image-shade {
        background-color: rgba(88, 89, 91, 0.08);
        width: 244px;
        height: 307px;
    }

    .cms-binding-fabrication .matched-row .image-shade:nth-of-type(1) {
        position: absolute;
        top: -25px;
        left: 25px;
    }

    .cms-binding-fabrication .matched-row .image-shade:nth-of-type(2) {
        position: absolute;
        top: -50px;
        left: 0;
    }

    .cms-binding-fabrication .matched-row .image-shade:nth-of-type(3) {
        position: absolute;
        top: -25px;
        right: 25px;
    }

    .cms-binding-fabrication .matched-row .image-shade:nth-of-type(4) {
        position: absolute;
        top: -50px;
        right: 0;
    }

    .cms-binding-fabrication .unmatched-row .image-shade:nth-of-type(1) {
        position: absolute;
        bottom: -25px;
        left: 25px;
    }

    .cms-binding-fabrication .unmatched-row .image-shade:nth-of-type(2) {
        position: absolute;
        bottom: -50px;
        left: 0;
    }

    .cms-binding-fabrication .unmatched-row .image-shade:nth-of-type(3) {
        position: absolute;
        bottom: -25px;
        right: 25px;
    }

    .cms-binding-fabrication .unmatched-row .image-shade:nth-of-type(4) {
        position: absolute;
        bottom: -50px;
        right: 0;
    }

    .cms-binding-fabrication .content-col.image-col {
        width: 592px;
        padding: 0 52px;
    }

    .cms-binding-fabrication .unmatched-row .content-col {
        padding: 45px 27px 0;
    }

    .cms-binding-fabrication .unmatched-row .image-col {
        padding: 0 52px;
    }

    .cms-binding-fabrication .mobile-image {
        display: none;
    }

    .cms-binding-fabrication .content-col h2 {
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        color: #000000;
        letter-spacing: 0.10px;
        line-height: 50px;
        font-size: 40px;
        margin: 0 0 18px;
    }

    .cms-binding-fabrication .content-col p {
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        color: #707070;
        line-height: 19px;
        font-size: 14px;
        margin: 0 0 27px;
    }

    .cms-binding-fabrication .content-col .binding-details {
        color: #2c2c2c;
        font-size: 14px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 300;
    }

    .cms-binding-fabrication .content-col .binding-details strong {
        font-weight: 600;
    }

    .cms-binding-fabrication .more-detail-btn {
        padding: 87px 15px 187px;
        text-align: center;
    }

    @media screen and (max-width: 1200px) {
        .cms-binding-fabrication .binding-banner-inner {
            height: 600px;
        }

        .cms-binding-fabrication .binding-banner-text-inner {
            font-size: 30px;
            line-height: 35px;
        }

        .cms-binding-fabrication .content-col.image-col {
            display: none;
        }

        .cms-binding-fabrication .content-col {
            width: 50%;
            margin: 0 0 50px;
        }

        .cms-binding-fabrication .content-col:last-of-type {
            margin: 0;
        }

        .cms-binding-fabrication .mobile-image {
            display: block;
            width: 100%;
        }

        .cms-binding-fabrication .content-col h2 {
            margin: 15px 0 18px;
        }

        .cms-binding-fabrication .more-detail-btn {
            padding: 87px 15px;
        }
    }

    @media screen and (max-width: 767px) {
        .cms-binding-fabrication .binding-banner-inner {
            height: 532px;
        }

        .cms-binding-fabrication .binding-banner-text-inner {
            font-size: 24px;
            letter-spacing: 0.10px;
        }

        .cms-binding-fabrication .content-col {
            display: block;
            width: 100%;
            max-width: 400px;
            margin: 0 auto 50px;
        }

        .cms-binding-fabrication .content-col:last-of-type {
            margin: 0 auto;
        }

        .cms-binding-fabrication .content-row.matched-row {
            background-color: transparent;
        }

        .cms-binding-fabrication .binding-content {
            margin: -104px 0 0 0;
        }
    }


    /* Special order CSS ----------------- */

    .cms-special-order .special-wrapper {
        font-size: 0;
    }

    .cms-special-order .special-wrapper-left {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 60%;
    }

    .cms-special-order .bomat-section {
        position: absolute;
        width: 280px;
        height: 150px;
        left: 216px;
        padding: 46px 15px;
        bottom: 176px;
        background-color: #000000;
        z-index: 1;
        text-align: center;
    }

    .cms-special-order .bomat-section img {
        max-width: 199px;
    }

    .cms-special-order .special-wrapper-right {
        display: inline-block;
        vertical-align: middle;
        width: 40%;
        padding: 125px 30px;
    }

    .cms-special-order .special-wrapper-right-inner {
        margin: 0 auto;
        max-width: 485px;
        width: 100%;
    }

    .cms-special-order .special-wrapper h3 {
        font-size: 22px;
        letter-spacing: 0.10px;
        line-height: 35px;
        color: #000000;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        margin: 0 0 27px;
    }

    .cms-special-order .special-wrapper p {
        font-size: 16px;
        letter-spacing: 0.10px;
        line-height: 24px;
        color: #58595B;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        margin: 0 0 33px;
    }

    .cms-special-order .page-header .left-menu .menu-item {
        color: #ffffff;
    }

    .cms-special-order .page-header .search-call {
        background-image: url('../images/white-search.svg');
    }

    .cms-special-order .special-wrapper-left .sider-item {
        position: relative;
        -webkit-transition: opacity 1.5s ease-in-out !important;
        -moz-transition: opacity 1.5s ease-in-out !important;
        -o-transition: opacity 1.5s ease-in-out !important;
        transition: opacity 1.5s ease-in-out !important;
    }

    .cms-special-order .special-wrapper-left .sider-item img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        z-index: 35;
    }

    .cms-special-order .special-wrapper-left .sider-overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 36;
        background-image: url('../images/slider-overlay.png');
        background-size: contain;
        background-position: top left;
        background-repeat: no-repeat;
    }

    @media screen and (min-width: 1025px) {
        .cms-special-order .page-header .black-logo {
            display: none;
        }

        .cms-special-order .page-header .white-logo {
            display: inline-block;
        }
    }

    @media screen and (max-width: 1024px) {
        .cms-special-order .special-wrapper-left {
            display: block;
            width: 100%;
        }

        .cms-special-order .special-wrapper-right {
            display: block;
            width: 100%;
            padding: 60px 20px;
        }

        .cms-special-order .bomat-section {
            left: 20px;
            bottom: 20px;
        }
    }

    @media screen and (max-width: 767px) {
        .cms-special-order .bomat-section {
            width: 250px;
            height: 120px;
            padding: 27px 15px;
        }

        .cms-special-order .special-wrapper-right {
            padding: 30px 20px 60px;
        }

        .cms-special-order .special-wrapper h3 {
            font-size: 20px;
            line-height: 25px;
        }
    }

    .cms-no-route .sidebar.sidebar-additional {
        display: none;
    }

    .cms-no-route .contact-notfound-details h4 {
        font-size: 50px;
        letter-spacing: 0.05px;
        color: #000000;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 70px;
        margin: 0 0 15px;
    }

    .cms-no-route .contact-notfound-details p {
        font-size: 24px;
        color: #94989A;
        letter-spacing: 0.50px;
        line-height: 34px;
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 600;
        margin: 0;
    }

    .cms-no-route .contact-wrapper .mobile-ask-title h4 {
        max-width: 100%;
        margin: 0 0 15px;
        font-size: 30px;
    }

    @media screen and (max-width: 991px) {
        .cms-no-route .contact-notfound-details h4 {
            font-size: 40px;
            line-height: 40px;
        }

        .cms-no-route .contact-notfound-details p {
            font-size: 18px;
            line-height: 25px;
        }
    }

    /* Prestigemills PDP page styles ------------------------ */

    @media screen and (min-width: 768px) {
        #s7viewer {
            min-height: 600px;
        }
    }

    @media screen and (min-width: 320px) and (max-width: 767px) {
        .pdp-product .slick-slide {
            width: 60px !important;
        }
    }

    @media screen and (min-width: 767px) and (max-width: 767px) {
        .pdp-product .slick-slide {
            width: 100px !important;
        }
    }


    /*body.cms-home.cms-index-index.page-layout-pm_custom_home .footer-menu div> div:nth-child(1) {
    margin: unset !important;
    padding: unset !important;
}*/

    .prestige_footer_scroll div>div:nth-child(1) {
        margin: unset !important;
        padding: unset !important;
    }

    .leftside_menu_down {
        position: relative;
        top: -15px;
    }

    /* Image set controls styles*/
    .s7toolbarcontainer {
        top: 600px !important;
    }


.product-wrapper {
    .plp-recent-loading {
        width: 100%;
        min-height: 100px;
        background-image: url(../images/busyicon.gif);
        background-size: auto;
        background-repeat: no-repeat;
        background-position: center;
    }
}

a.viewed-option.loading {
    position: relative;
    &::after {
        position: absolute;
        content: " ";
        width: 20px;
        height: 6px;
        background-image: url(../images/busyicon.gif);
        background-size: contain;
        background-repeat: no-repeat;
        top: 42%;
        right: -27px;
    }
}

.collection-filters {
    .filter-heading {
        .recent-text {
            display: none;
        }
    }
}
